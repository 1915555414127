import * as React from "react";
import { Flex, Box } from "reflexbox";
import { useState } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import thumbnailSrc from "../../static/thumbnail.jpg";
import Gallery from "../components/gallery";
import { GalleryContainer } from "../components/layout/container";
import { Proposal } from "../components/proposal/Proposal";
import Modal, { ModalData } from "../components/modal/Modal";
import { Center, H2 } from "../components/typography/styled/typography";
import { Ornament } from "../components/layout/ornament";
import { Lang, Translate } from "../components/tr";

const Portfolio = ({ data, pageContext: { lang = Lang.cs }, location }) => {
  const [modalData, setModalData] = useState<ModalData | null>(null);
  /*const imgSets: ImgSet[] = useMemo(
    () =>
      data.allMarkdownRemark.edges.map(({ node }) =>
        getImageData(data.gallery, node)
      ),
    []
  );*/

  return (
    <Layout scrollDisable={modalData !== null} lang={lang} location={location}>
      <SEO lang={lang} title="Watercolor Gallery" image={thumbnailSrc} />
      <Modal
        lang={lang}
        title={modalData !== null ? modalData.title : ""}
        data={modalData}
        onClose={() => setModalData(null)}
      />
      {/*<Container>
        <Ornament />
        <Center>
          <H1>
            <Translate text={"Featured artwork for sale"} />
          </H1>

        </Center>
        {imgSets.map((set) => {
          return (
            <FeaturedInSection
              key={set.image.alt}
              imgSet={set}
              onOpen={() => setModalData(set)}
              lang={lang}
            />
          );
        })}
      </Container>*/}

      <GalleryContainer>
        <Ornament />
        <Center>
          <H2>
            <Translate text={"Sales Gallery"} />
          </H2>
          <cite>
            “
            <Translate
              text={
                "Music is my God, and it is the only love that has never left me."
              }
            />
            ” &mdash;Ville Valo
          </cite>
        </Center>
        <Flex flexWrap={"wrap"}>
          <Box width={[1, 1, 1, 1]} py={3} mt={[3]}>
            <Gallery
              nodes={data.allFile.nodes}
              meta={data.galleryMeta.watercolor}
              onOpenDetail={setModalData}
            />
          </Box>
        </Flex>
      </GalleryContainer>
      <Proposal />
    </Layout>
  );
};

export default Portfolio;

export const galleryQuery = graphql`
  query galleryWatercolorQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: "**/watercolor-gallery/*.md" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          excerpt(pruneLength: 410)
          frontmatter {
            date
            year
            title
            tags
            directory
            dimension
            material
            variants {
              name
              price
            }
          }
        }
      }
    }

    gallery: allFile(
      filter: { relativePath: { glob: "gallery/watercolor/*/*.jpg" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        base
        relativePath
        childImageSharp {
          id
          original {
            width
            height
            src
          }
          fluid(maxWidth: 400, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
          big: fluid(maxWidth: 1024, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 623, height: 450, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    galleryMeta: galleryYaml {
      watercolor {
        name
        availability
        title
        year
        dimension
        material
        variants {
          name
          price
        }
      }
    }

    allFile(
      filter: { relativePath: { glob: "gallery/watercolor/*.jpg" } }
      sort: { fields: [name], order: DESC }
    ) {
      nodes {
        base
        childImageSharp {
          id
          original {
            width
            height
            src
          }
          fixed(width: 400, height: 400, quality: 100) {
            width
            height
            src
          }
        }
      }
    }
  }
`;
